import React from 'react';
import './styles.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { className, containerClassName, children, noContainer, sliceName, jumpId, id } = props;
  return (
    <section id={id} data-jump-id={jumpId} className={`section ${className || ''}`} data-slice={isDev ? sliceName : null}>
      {noContainer ? children : <div className={`container ${containerClassName || ''}`}>{children}</div>}
    </section>
  );
};

export default Section;
