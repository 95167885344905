import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Section, Slice } from 'components';
import planet from 'images/red-wire-planet.svg';
import * as styles from './home.module.scss'

const Home = ({ data }) => {
  const {
    prismicHome: {
      data: {
        heading,
        subheading,
        meta_title: metaTitle,
        meta_description: metaDescription,
        open_graph_image, ogImage,
        schema,
        body,
      }
    }
  } = data;

  const headingHtml = heading?.html.replace('{b}', '<strong class="heavy">')
                                   .replace('{/b}', '</strong>')
                                   .replace('{circle}', '<span class="blueCircle" />');
  const subheadingHtml = subheading?.html;

  return (
    <Layout>
      <div className={styles.sectionWrap}>
        <Section className={styles.section} sliceName={'Homepage Template'}>
          <div className={styles.heading} dangerouslySetInnerHTML={{__html: headingHtml}} />
          <div className={styles.subheading} dangerouslySetInnerHTML={{__html: subheadingHtml}} />
        </Section>
        <img src={planet} alt="Red wired planet" className={styles.redPlanet}/>
      </div>
      {body.map((slice) => {
        return (
          <Slice
            key={slice.id}
            data={slice}
          />
        )
      })}
    </Layout>
  )
}

export default Home;
export { Head } from '../components/common/SEO/index';

export const homeQuery = graphql`
  query Home {
    prismicHome {
      data {
        heading {
          html
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        schema {
          text
        }
        subheading {
          html
        }
        body {
          ...HomeTwoColWithRepeater
          ...HomeContentBlock
          ...HomeTwoColWithMedia
          ...HomeTwoColContent
        }
      }
    }
  }
`