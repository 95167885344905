import React from 'react';

const Twitter = ({ type, username, title, desc, image }) => {
  return (
    <>
      {username && <meta name="twitter:creator" content={username} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={desc} />
    </>
  );
};

export default Twitter;
