import * as React from 'react';
import SEO from './SEO';

export function Head() {
  return (
    <>
      <SEO />
    </>
  )
}
