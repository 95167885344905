// extracted by mini-css-extract-plugin
export var animateScrollIn = "home-module--animateScrollIn---kj0e";
export var animateScrollOut = "home-module--animateScrollOut--gVpQ3";
export var colorSequence = "home-module--colorSequence--rwG3p";
export var diagonalFadeIn = "home-module--diagonalFadeIn--+JtaS";
export var grow = "home-module--grow--7CfJk";
export var growAndShrink = "home-module--growAndShrink--M2cN5";
export var growAndShrinkSubtle = "home-module--growAndShrinkSubtle--hgUqA";
export var heading = "home-module--heading--YrSod";
export var moveBg = "home-module--move-bg--Vpj1T";
export var redPlanet = "home-module--redPlanet--V8yuG";
export var rotateLoop = "home-module--rotateLoop--lo5l7";
export var section = "home-module--section--Sm8hP";
export var sectionWrap = "home-module--sectionWrap--YhcUm";
export var spin = "home-module--spin--hJrpz";
export var spinCounter = "home-module--spinCounter--HLZVD";
export var stickerFloat = "home-module--stickerFloat--tjO-a";
export var subheading = "home-module--subheading--tffnM";