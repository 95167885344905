import React from 'react';
import { Header, Footer } from 'components';
import '../../../sass/global/styles.scss';

const Layout = (props) => {
  const {
    children,
  } = props

  return (
    <main>
      <Header/>
      {children}
      <Footer />
    </main>
  )
}

export default Layout;

