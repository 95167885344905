import React from 'react';
import { ScrollToElement } from 'components';
import * as styles from './styles.module.scss';
import logoUrl from 'images/ld-org-logo.svg';

const Header = () => {
  const menuItems = [
    {
      url: '#ourcauses',
      linkText: 'Our causes',
      isJumpTo: true,
    },
    // {
    //   url: '#employeeinvolvement',
    //   linkText: 'Employee involvement',
    //   isJumpTo: true,
    // },
    {
      url: 'https://launchdarkly.com/',
      linkText: 'LaunchDarkly.com',
      isJumpTo: false,
      rel: 'nofollow noopener noreferrer',
      target: '_blank',
    }
  ];

  const menuOnClick = (e, isJumpTo, url) => {
    if (isJumpTo) {
      e.preventDefault();
      const jumpId = url.split('#')[1];
      ScrollToElement({selector: jumpId});
    }
  }

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <img src={logoUrl} alt="LaunchDarkly.org logo"/>
      </div>
      <div className={styles.right}>
        <nav>
          <ul className={styles.menuItems}>
            {menuItems.map((menuItem) => {
              return (
                <li className={styles.menuItem} key={menuItem.linkText}>
                  <a rel={menuItem.rel} target={menuItem.target} href={menuItem.url} onClick={(e) => {menuOnClick(e, menuItem.isJumpTo, menuItem.url)}}>
                    {menuItem.linkText}
                  </a>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </header>
  )
};

export default Header;