import React from 'react';

const ScrollToElement = ({ selector, offset = 0, scrollDelay = 400 }) => {
  const elToScrollTo = document.querySelector(`section[data-jump-id="${selector}"]`);
  if (elToScrollTo) {
    setTimeout(() => {
      const elPos = elToScrollTo.getBoundingClientRect().top;
      const scrollToPosition = elPos + window.pageYOffset - offset;
      // Can't use scroll into view if needed plugin as it doesn't allow offset
      // this page have sticky header at top and without offset top bit will hidden
      // https://stackoverflow.com/questions/49820013/javascript-scrollintoview-smooth-scroll-and-offset
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      })
    }, scrollDelay);
  }
}

export default ScrollToElement;