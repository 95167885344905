import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './styles.scss';

const Image = (props) => {
  const { image, alt, className = '', imgStyle, loading, ...otherProps } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;

  let imageData;
  const imgWidth = otherProps.width
  const imgHeight = otherProps.height

  if (image?.gatsbyImageData) {
    imageData = getImage(image.gatsbyImageData);
  } else {
    imageData = getImage(image?.localFile);
  }

  if (imageData) {
    return (
      <GatsbyImage
        className={`gatsby-image ${className}`}
        loading={loading || 'eager'}
        image={imageData}
        alt={image.alt || alt || ''}
        imgStyle={imgStyle}
        {...otherProps}
      />
    );
  }

  if (image.url) {
    return (
      <div className={`gatsby-image no-sharp ${className} moocow`}>
        <img
          src={image.url}
          alt={image.alt || alt}
          loading={loading != 'eager' || loading != 'lazy' ? 'lazy' : loading}
          width={imgWidth}
          height={imgHeight}
        />
      </div>
    );
  }

  return <div className={`gatsby-image placeholder ${className}`} />;
};

export default Image;
