// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--0RIGz";
export var animateScrollOut = "styles-module--animateScrollOut--GuHv5";
export var colorSequence = "styles-module--colorSequence--u+h7H";
export var copyright = "styles-module--copyright--Mo5lE";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--rvYYk";
export var footer = "styles-module--footer--5TDEr";
export var footerLink = "styles-module--footerLink--bGGDR";
export var footerTop = "styles-module--footerTop--yUxFV";
export var grow = "styles-module--grow--q4xJm";
export var growAndShrink = "styles-module--growAndShrink--pqR94";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--FVbQT";
export var ldLogo = "styles-module--ldLogo--EosE-";
export var moveBg = "styles-module--move-bg--LBn5t";
export var rotateLoop = "styles-module--rotateLoop--Znaqp";
export var spin = "styles-module--spin--uDgfq";
export var spinCounter = "styles-module--spinCounter--PE7Ak";
export var stickerFloat = "styles-module--stickerFloat--oKULO";