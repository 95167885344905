// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--kYwzc";
export var animateScrollOut = "styles-module--animateScrollOut--Fqk51";
export var colorSequence = "styles-module--colorSequence--BpZPt";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--h2kC8";
export var grow = "styles-module--grow--l7kUW";
export var growAndShrink = "styles-module--growAndShrink--IZ+5O";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--KgbCK";
export var moveBg = "styles-module--move-bg--g6ER4";
export var rotateLoop = "styles-module--rotateLoop--IXVNF";
export var spin = "styles-module--spin--C6RtU";
export var spinCounter = "styles-module--spinCounter--Lx9Kq";
export var stickerFloat = "styles-module--stickerFloat--C2akg";
export var withArrow = "styles-module--withArrow--xjCOe";