import React from 'react';
import { Link } from 'components';
import ldOrgIcon from 'images/icon-ld-org.svg';
import {ReactComponent as LdLogo} from 'images/launchdarkly.svg';
import * as styles from './styles.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <img src={ldOrgIcon} alt="LaunchDarkly.org Icon"/>
        <Link className={styles.footerLink} to="https://launchdarkly.com/" target="_blank">
          <LdLogo className={styles.ldLogo} />
        </Link>
      </div>
      <div>
        <div className={styles.copyright}>&copy;{new Date().getFullYear()} Catamorphic Co.</div>
      </div>
    </footer>
  )
}

export default Footer;